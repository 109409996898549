@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'DM Sans', sans-serif !important;
    font-feature-settings: 'kern' !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.image-gallery.fullscreen-modal {
  z-index: 100 !important;
}

.image-gallery {
  .image-gallery-content.fullscreen .image-gallery-slide {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
    margin: 0 auto;
  }

  .image-gallery-content:not(.fullscreen) {

    img,
    video {
      height: 100% !important;
      object-fit: contain;
    }
  }

  .image-gallery-icon:focus {
    outline: 2px solid theme('colors.brand.500') !important;
  }

  .image-gallery-icon.image-gallery-right-nav:hover {
    color: theme('colors.brand.500');
  }

  .image-gallery-icon.image-gallery-left-nav:hover {
    color: theme('colors.brand.500');
  }

  .image-gallery-bullets .image-gallery-bullet:hover,
  .image-gallery-bullet:focus {
    background: theme('colors.brand.500');
    border-color: theme('colors.brand.500');
  }

  .image-gallery-fullscreen-button:hover,
  .image-gallery-fullscreen-button:focus,
  .image-gallery-svg:hover,
  .image-gallery-svg:focus {
    color: theme('colors.brand.500');
  }

  .image-gallery-thumbnails {

    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:focus,
    .image-gallery-thumbnail:hover {
      border-color: theme('colors.brand.500');
    }
  }

  .image-gallery-thumbnail-inner {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
    margin: 0 auto;

    img,
    video {
      height: 100% !important;
      object-fit: contain;
    }
  }
}